import React, { useState, useEffect } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { Controller } from "react-hook-form";

///<summary>
///Author: Ong Sze Hua
///</summary>
const FileUpload = (props) => {
  const [errColor, setErrColor] = useState("#77C0F1");
  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState("");

  const{
    setDocuments,
    ...rest
  } = props

  ///<summary>
  ///Author: Ong Sze Hua
  ///</summary>
  const onDrop = (acceptedFiles, onChange) => {
    console.log("start", acceptedFiles);
    if (Object.keys(acceptedFiles).length === 0) {
      let color = "red";
      setErrColor(color);
      onChange("");
    } else {
      let color = "#77C0F1";
      setErrColor(color);

      onChange(acceptedFiles);
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      console.log(acceptedFiles[0].path);
      setFileName(acceptedFiles[0].path);

      if(setDocuments) {
        setDocuments({
            name: acceptedFiles[0].path,
            fileSize: Math.round((acceptedFiles[0].size/(1024*1024))*100)/100 + "MB",
        })
      }

      var blobPromise = new Promise((resolve, reject) => {
        const reader = new window.FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
      });
      blobPromise.then((value) => {
        // onChange(value);
        //props.setImage(value);
      });
    }
  };

  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  ///<summary>
  ///Author: Ong Sze Hua
  ///</summary>
  function render(files) {
    let msg = null;
    Object.keys(files).length !== 0
      ? files.map((file) => (msg = truncate(file.name)))
      : (msg = "Please drop only .png, .jpeg, .pdf, .docx, .ppt files");
    return msg;
  }

  function truncate(str) {
    return str.length > 10 ? str.substring(0, 20) + "..." : str;
  }

  return (
    <Controller
      render={({ field: { onChange } }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "150px",
            border: " 2px dashed",
            borderColor: errColor,
          }}
        >
          <Dropzone
            onDrop={(acceptedFiles) => onDrop(acceptedFiles, onChange)}
            multiple={false}
            accept="image/png, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint"
          >
            {({ getRootProps, getInputProps, isDragReject, acceptedFiles }) => (
              <div
                {...getRootProps()}
                style={{ width: "100%", height: "100%" }}
                className="d-flex justify-content-center align-items-center"
                tabIndex="-1"
              >
                <input {...getInputProps({ onChange })} />

                <aside style={thumbsContainer}>{thumbs}</aside>
                <br></br>
                <span style={{ color: errColor, cursor: "default" }}>
                  {isDragReject
                    ? "Please submit a valid file"
                    : render(acceptedFiles)}
                </span>
              </div>
            )}
          </Dropzone>
        </div>
      )}
      {...props}
    />
  );
};

export default FileUpload;
