import React, { useMemo, useState, useRef, useEffect, forwardRef } from "react";
import { Card, CardHeader, CardBody, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useForm, useFieldArray, FormProvider } from "react-hook-form";
import CustomInput from "components/form/CustomInput";
import CustomSelect from "components/form/CustomSelect";  
import CustomDatePicker from "components/form/CustomDatePicker";
import CustomDateRangePicker from "components/form/CustomDateRangePicker";
import { useHistory, Link, useLocation } from 'react-router-dom';
import { Config, ConfigEnum } from 'util/Config';
import moment from 'moment';
import { NumWeeks, ContractType, EnumContractType } from "util/Constant";
import  DocumentFileUpload  from "components/form/DocumentFileUpload";
import DocumentTable from "components/tables/DocumentTable";

const dateFns = require( "date-fns");
const { addWeeks } = dateFns;

const Contracts =  (props) => {
    const { register, control, handleSubmit, setValue, errors, watch, reset } = useForm({
            status: 0,
            priority: 0,
        });
    
    const history = useHistory();
    
    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);
    const [showDocument, setShowDocument] = useState(false);
    const [documentsList, setDocumentsList] = useState([]);
    const [documents, setDocuments] = useState({});
    {/*new function as handler */}
    const showDocumentClicked = (data) => {
      setShowDocument((prev) => !prev);
    };

    const {
        handleNext,
    } = props;

    const onSubmit = (data) => {
            data["documents"]= documentsList;
            //reformat original data format
            data["contractualStartDate"]= changeDateFormat("contractualStartDate");
            data["clientRequestedDueDate"]= changeDateFormat("clientRequestedDueDate");
            data["overallProjectDueDate"]= changeDateFormat("overallProjectDueDate");
            console.log(data);
            handleNext();
            
    }

    // funtion used to reformat the original date 
    const changeDateFormat = (data) =>{
        return(moment(watch(data)).format("MM/DD/YYYY"))
    }

    const[contractualStartDate, setContractualStartDate]= useState(new Date());
    const[fabricationDeliveryDate, setFabricationDeliveryDate]= useState(new Date());
    const[physicalInstallationDate, setPhysicalInstallationDate]= useState(new Date());
    const[testingWiringDate, setTestingWiringDate]= useState(new Date());
    const[contractualEndDate, setContractualEndDate]= useState(new Date());
    
    

    const setupDate = ( data ) =>{
        alert(data)
        setContractualStartDate(data);
        setFabricationDeliveryDate(data);
        setPhysicalInstallationDate(data);
        setTestingWiringDate(data);
        setContractualEndDate(data);
    };

    const setupFabricationDate = ( data ) =>{
        setFabricationDeliveryDate(addWeeks(contractualStartDate,data));
        setPhysicalInstallationDate(addWeeks(contractualStartDate,data));
        setTestingWiringDate(addWeeks(contractualStartDate,data));
        setContractualEndDate(addWeeks(contractualStartDate,data));
    };

    const setupInstallationDate = ( data ) =>{
        setPhysicalInstallationDate(addWeeks(fabricationDeliveryDate,data));
        setTestingWiringDate(addWeeks(fabricationDeliveryDate,data));
        setContractualEndDate(addWeeks(fabricationDeliveryDate,data));
    };

    const setupTestingDate = ( data ) =>{
        setTestingWiringDate(addWeeks(physicalInstallationDate,data));
        setContractualEndDate(addWeeks(physicalInstallationDate,data));
    };


    const addToDocumentsList = (data) => {
        //add the units into an array for dynamic display n to be stored afterwards
        setDocumentsList([...documentsList,
          {
            documents,
            contractType: EnumContractType[watch("contractType")],
            remarks: watch("remarks"),
          }
            ]);
            showDocumentClicked();
        };
        console.log(documentsList)


    return (
        <form id="ContractForm" onSubmit={handleSubmit(onSubmit)}>
        <Row style={{backgroundColor:"#F0F9FF", margin: "10px"}}>
            <Col md={10}>
                <h1 className="page-subheader" style={{borderBottom: "2px solid #77C0F173"}}>
                    Contracts
                </h1>
            </Col>
            <Col md={2}>
                <button type="reset" className='btn btn-default d-flex-center' style={{color:"#0099FF" , borderColor:"#0099FF", justifyContent:"center", margin:"auto", width:"100%"}}>Reset Fields &nbsp; <i className="fas fa-undo"></i></button>
            </Col>
            <Col md={6}>
                <label className="custom-input-label">Contractual Start Date</label>
                    <CustomDatePicker
                      control={control}
                      {...register("contractualStartDate", false)}
                      name="contractualStartDate"
                      label="Contractual Start Date"
                      onChange={setupDate}
                    />
            </Col>
            <Col md={6}>
                <label className="custom-input-label">Fabrication & Delivery (weeks)</label>
                <div style={{display : "inline-flex" , margin : "auto", width: "100%"}}>
                    <div style={{width:"50%"}}>
                    <CustomSelect
                      control={control}
                      name="fabricationDeliveryInWeeks"
                      label="Fabrication Delivery In Weeks"
                      placeholder="Choose the number of weeks"
                      options={NumWeeks}
                      rules={{
                          required: true,
                      }}
                      onChange={setupFabricationDate}
                      style={{display:"inline-block", width: "50%"}}
                    /></div>
                    <CustomInput
                      {...register("fabricationDeliveryDate", {
                        required: "Fabrication Delivery Date is required",
                      })}
                      name="fabricationDeliveryDate"
                      label="Fabrication Delivery Date"
                      isEditable = {false}  
                      showLabel
                      value={fabricationDeliveryDate.toLocaleDateString("en-US", { // you can use undefined as first argument
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })}
                      style={{display:"inline-block", width: "50%"}}
                    />
                    </div>
            </Col>
            <Col md={6}>
                <label className="custom-input-label">Physical Installation (weeks)</label>
                <div style={{display : "inline-flex" , margin : "auto", width: "100%"}}>
                    <div style={{width:"50%"}}>
                    <CustomSelect
                      control={control}
                      name="physicalInstallationInWeeks"
                      label="Physical Installation In Weeks"
                      placeholder="Choose the number of weeks"
                      options={NumWeeks}
                      rules={{
                          required: true,
                      }}
                      onChange={setupInstallationDate}
                      style={{display:"inline-block", width: "50%"}}
                    /></div>
                    <CustomInput
                      {...register("physicalInstallationDate", {
                        required: "Physical Installation Date is required",
                      })}
                      name="physicalInstallationDate"
                      label="Physical Installation Date"
                      isEditable = {false}  
                      showLabel
                      value={physicalInstallationDate.toLocaleDateString("en-US", { // you can use undefined as first argument
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })}
                      style={{display:"inline-block", width: "50%"}}
                    />
                    </div>
            </Col>
            <Col md={6}>
                <label className="custom-input-label">Testing & Wiring (weeks)</label>
                <div style={{display : "inline-flex" , margin : "auto", width: "100%"}}>
                    <div style={{width:"50%"}}>
                    <CustomSelect
                      control={control}
                      name="testingWiringInWeeks"
                      label="Testing & Wiring In Weeks"
                      placeholder="Choose the number of weeks"
                      options={NumWeeks}
                      rules={{
                          required: true,
                      }}
                      onChange={setupTestingDate}
                      style={{display:"inline-block", width: "50%"}}
                    /></div>
                    <CustomInput
                      {...register("testingWiringDate", {
                        required: "Teating & Wiring Date is required",
                      })}
                      name="teatingWiringDate"
                      label="Teating & Wiring Date"
                      isEditable = {false}  
                      showLabel
                      value={testingWiringDate.toLocaleDateString("en-US", { // you can use undefined as first argument
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })}
                      style={{display:"inline-block", width: "50%"}}
                    />
                    </div>
            </Col>
            <Col md={4}>
                <label className="custom-input-label">Contractual Start Date</label>
                    <CustomInput
                      {...register("contractualEndDate", {
                        required: "Contractual End Date is required",
                      })}
                      name="contractualEndDate"
                      label="Contractual End Date"
                      isEditable = {false}  
                      showLabel
                      value={contractualEndDate.toLocaleDateString("en-US", { // you can use undefined as first argument
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })}
                    />   
            </Col>
            <Col md={4}>
                <label className="custom-input-label">Client Requested Due Date</label>
                    <CustomDatePicker
                      control={control}
                      {...register("clientRequestedDueDate", false)}
                      name="clientRequestedDueDate"
                      label="Client Requested Due Date"
                      onChange={null}
                    />
            </Col>
            <Col md={4}>
                <label className="custom-input-label">Overall Project Due Date</label>
                    <CustomDatePicker
                      control={control}
                      {...register("overallProjectDueDate", false)}
                      name="overallProjectDueDate"
                      label="Overall Project Due Date"
                      onChange={null}
                    />
            </Col>


            <Col md={10}>                
                <br />
                  <h1 className="page-subheader" style={{borderBottom: "2px solid #77C0F173"}}>
                      Lists of Contractual Documents
                  </h1>
            </Col>
            <Col md={2} style={{marginTop: "auto"}}>
                <div className='btn btn-default d-flex-center filter-btn' style={{color:"#0099FF" , borderColor:"#0099FF", justifyContent:"center"}} onClick={showDocumentClicked}>
                    <span>Add new document +</span>
                </div> </Col>
                    { /* action handler if __ is true then to what from here eh is put in the table inside here then rmb onClick ^ put the function that setShowUnit to true eh function*/
                    showDocument && (
                        <Col md={12}>
                            <Row style={{backgroundColor:"white", margin: "10px"}}>
                                <Col><br /></Col>
                                    <Col md={12}>
                                        <h4>
                                            Add New Document
                                        </h4>
                                        <DocumentFileUpload
                                          control={control}
                                          name="documentFile"
                                          setDocuments={setDocuments}
                                        />
                                    </Col>
                                    <Col><br /></Col>
                                    <Col md={12}>
                                        <label className="custom-input-label">Contract Type</label>
                                            <CustomSelect
                                                control={control}
                                                name="contractType"
                                                label="Contract Type"
                                                placeholder="Choose the contract type"
                                                options={ContractType}
                                                rules={{
                                                    required: true,
                                                }}
                                            /> 
                                    </Col>
                                    <Col><br /></Col>
                                    <Col md={12}>
                                        <label className="custom-input-label">Remarks</label>
                                            <CustomInput
                                              {...register("remarks", {
                                                required: "Remarks is required",
                                              })}                    
                                              name="remarks"
                                              label="Remarks"           
                                              showLabel
                                            />
                                    </Col>
                                          
                                          
                                    <Col md={12}>
                                        <br />
                                        <button type="button" onClick={() => addToDocumentsList()} className='btn btn-default d-flex-center filter-btn' style={{color:"#0099FF" , borderColor:"#0099FF", justifyContent:"center", marginRight:"6px", width: "100%"}}>Add to Documents List</button>
                                        <br />
                                    </Col>
                            </Row>
                        </Col> 

                    ) 
            }
            {<Col md={12}>                
                  <br />
                  <DocumentTable
                    tableTitle={(
                        <div className="d-flex flex-row align-items-center justify-between w-100">
                            <h1 className="page-subheader">Documents List</h1>      
                        </div>
                    )}         
                    data={documentsList}
                />
                <br /><br />
            </Col>}
            <Col md={12}>
                <button type="submit" className="btn btn-themed btn-block">Continue</button>
                <br />
            </Col> 
            </Row>
            </form>
        )
    }
    
    export default Contracts;