import React, { useMemo } from "react";
import ReactTooltip from 'react-tooltip';
import moment from "moment";
import { DateFormat, AppFeatureKeys, Permission } from 'util/Constant';
import ReactTable from 'components/react-table/ReactTable';

/// <summary>
/// Author: Samuel
/// </summary>
const ProjectTable = ({ data, tableRef, ...rest }) => {
    const columns = useMemo(() => [
        {
            Header: "Project Serial",
            accessor : "projectSerial"
        },
        {
            Header: "Project Status",
            accessor: "projectStatus"
        },
        {
            Header: "Model",
            accessor: "model"
        },
        {
            Header: "Owner",
            accessor: "owner"
        },
        {
            Header: "Contracts",
            accessor: "contacts"
        },
        {
            Header: "Site Notes",
            accessor: "siteNote"
        },
        {
            Header: "Shop Drawing Status",
            accessor: "shopDrawingStatus"
        },
        {
            Header: "Work Order Status",
            accessor: "workOrderStatus"
        },
        {
            Header: "JKKP Status",
            accessor: "jkkpStatus"
        },
        {
            Header: "Payment Status",
            accessor: "paymentStatus"
        },
        {
            Header: "Action",
            // Cell: ({ row }) => (
            //     <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            //         <div
            //             data-for="edit-btn"
            //             data-tip="Edit Project"
            //             className="btn-round expand-theme btn-sm"
            //             onClick={() => { toggle(); setProject(row.original) }}
            //         >
            //             <i class="fas fa-edit"></i>
            //         </div>
            //         {
            //             row.original.project?.contract?.currentVersion?.signedDocument &&
            //             <div
            //                 className="btn-round expand-theme btn-sm m-l-5"
            //                 data-for="tasks-btn"
            //                 data-tip="Project Tasks"
            //                 onClick={() => history.push(WebUrl._PROJECT_TASKS, row.original)}
            //             >
            //                 <i class="fas fa-list-ol"></i>
            //             </div>
            //         }
            //         {
            //             row.original.status === 3 && <>
            //                 <div style={{ display: 'inline-block', height: '1.4rem', width: '1px', backgroundColor: '#CCC', marginLeft: '0.6rem', marginRight: '0.6rem' }}></div>
            //                 <div
            //                     className="btn-round expand-theme btn-sm"
            //                     data-for="complete-btn"
            //                     data-tip="Complete Tasks"
            //                     onClick={() => completeProjectHandler(row.original.id)}
            //                 >
            //                     <i class="fas fa-check-circle"></i>
            //                 </div>
            //             </>
            //         }
            //         <ReactTooltip id="edit-btn" />
            //         <ReactTooltip id="tasks-btn" />
            //         <ReactTooltip id="complete-btn" />
            //     </div>
            // ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ], []);

    return <ReactTable columns={columns} data={data} ref={tableRef} {...rest} />;
}

export default ProjectTable;