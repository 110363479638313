import React, { useMemo } from "react";
import ReactTooltip from 'react-tooltip';
import moment from "moment";
import { DateFormat, AppFeatureKeys, Permission } from 'util/Constant';
import ReactTable from 'components/react-table/ReactTable';

/// <summary>
/// Author: Samuel
/// </summary>
const ElevatorModelTable = ({ data, tableClickEvent, setOpen, tableRef, ...rest }) => {
    const columns = useMemo(() => [
      {
        Header: "Serial Number",
        Cell: ({ row }) => <b>{row.original.serialNumber}</b>,
      },
      {
        Header: "Model",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Price (RM)",
        accessor: "price",
      },
      {
        Header: "Creation Date",
        accessor: (d) => {
          return moment(d.createdAt).local().format("DD-MM-YYYY hh:mm:ss A");
        },
      },
      {
        Header: "Modified Date",
        accessor: (d) => {
          return moment(d.modifiedAt).local().format("DD-MM-YYYY hh:mm:ss A");
        },
      },
      {
        Header: "Created By",
        accessor: "created_by.username",
      },
      {
        Header: "Modified By",
        accessor: "last_modified_by.username",
      },
      {
        Header: " ",
        Cell: ({ row }) => (
          <>
           
          </>
        ),
        disableSortBy: true,
        disableFilters: true,
        style: { overflow: "visible", textAlign: "right" },
        sticky: "right",
  
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ], []);

    return <ReactTable columns={columns} data={data} ref={tableRef} {...rest} />;
}

export default ElevatorModelTable;