import React, { useState } from "react";
import classnames from "classnames";
import { Controller } from "react-hook-form";
import ReactDatepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomInput from "./CustomInput";

/// <summary>
/// Author: Christopher Chan
/// </summary>
const CustomDateRangePicker = (props) => {
  const [toggleClass, setToggleClass] = useState(props.defaultValue ?? false);
  const [selectDate, setSelectedDate] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div>
      <ReactDatepicker
        selected={startDate}
        onChange={(e) => {
            onChange(e);
            console.log(e);
            setSelectedDate(e);
            setToggleClass(Boolean(e));
          }}
        onBlur={({ target: { value } }) => {
            setToggleClass(Boolean(value));
        }}
        showMonthDropdown
        showYearDropdown
        dropdownMode={"select"}
        selectsRange
        onFocus={() => {
            setToggleClass(true);
          }}
        wrapperClassName={classnames({ "datepicker-selected": toggleClass })}
   
        customInput={
            <CustomInput
              label={props?.label}
              icon={props?.icon}
              isEditable={false}
              defaultValue={props?.defaultValue ? startDate.toLocaleDateString() + endDate.toLocaleDateString() : ""}
            />
        }
        
      />
      {startDate && endDate && (
        <div>
          <p>Start Date: {startDate.toLocaleDateString()}</p>
          <p>End Date: {endDate.toLocaleDateString()}</p>
        </div>
      )}
    </div>
  );
};

CustomDateRangePicker.defaultProps = {
  defaultValue: null,
};

export default CustomDateRangePicker;
