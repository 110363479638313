import React, { useState, useEffect } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { Controller } from "react-hook-form";
import CustomInput from "components/form/CustomInput";

///<summary>
///Author: Ong Sze Hua
///</summary>
const NewProjectFileUpload = (props) => {
    const {
        name,
        setShopDrawing,
        setWorkOrder,
        setTestingCommissioningForm,
        setCompletionForm,
        setHandoverForm,
        ...rest
        
      } = props;
  const [errColor, setErrColor] = useState("#0099FF");
  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState("");

  ///<summary>
  ///Author: Ong Sze Hua
  ///</summary>
  const onDrop = (acceptedFiles, onChange) => {
    console.log("start", acceptedFiles);
    if (Object.keys(acceptedFiles).length === 0) {
      let color = "red";
      setErrColor(color);
      onChange("");
    } else {
      let color = "#0099FF";
      setErrColor(color);

      onChange(acceptedFiles);
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      console.log(acceptedFiles[0].path);
      setFileName(acceptedFiles[0].path);
      if(setShopDrawing) {
        setShopDrawing(acceptedFiles[0].path)
      }
      if(setWorkOrder) {
        setWorkOrder(acceptedFiles[0].path)
      }
      if(setTestingCommissioningForm) {
        setTestingCommissioningForm(acceptedFiles[0].path)
      }
      if(setCompletionForm) {
        setCompletionForm(acceptedFiles[0].path)
      }
      if(setHandoverForm) {
        setHandoverForm(acceptedFiles[0].path)
      }
      var blobPromise = new Promise((resolve, reject) => {
        const reader = new window.FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
      });
      blobPromise.then((value) => {
        // onChange(value);
        //props.setImage(value);
      });
    }
  };

  ///<summary>
  ///Author: Ong Sze Hua
  ///</summary>
  function render(files) {
    let msg = null;
    Object.keys(files).length !== 0
      ? files.map((file) => (msg = truncate(file.name)))
      : (msg = "Browse...");
    return msg;
  }

  function truncate(str) {
    return str.length > 10 ? str.substring(0, 20) + "..." : str;
  }

  return (
    <Controller
      render={({ field: { onChange } }) => (
        <>
        <div style={{display : "inline-flex" , margin : "auto", width: "100%"}}>
        <CustomInput
          name={name}
          label= "link to attachment"
          isEditable={false}
          showLabel
          value={fileName}
          style={{margin:"auto", textDecorationLine:'underline'}}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "30px",
            Width: "100%",
            borderRadius: "4px",
            border: " 1.5px solid",
            borderColor: errColor,
            fontWeight: "bold",
            margin: "auto auto auto 5px",
            padding: "5.6875px 9.75px"
          }}
        >
          <Dropzone
            onDrop={(acceptedFiles) => onDrop(acceptedFiles, onChange)}
            multiple={false}
            accept="image/png, image/jpeg"
          >
            {({ getRootProps, getInputProps, isDragReject, acceptedFiles }) => (
              <div
                {...getRootProps()}
                style={{ width: "100%", height: "100%" }}
                className="d-flex justify-content-center align-items-center"
                tabIndex="-1"
              >
                <input {...getInputProps({ onChange })} />

                
                <br></br>
                <span style={{ color: errColor, cursor: "default", width: "100%"}}>
                  {isDragReject
                    ? "Please submit a valid file"
                    : "Browse..."}
                </span>
              </div>
            )}
          </Dropzone>
        </div>
        </div>
        </>
      )}
      {...props}
    />
  );
};

export default NewProjectFileUpload;
