import React, { useMemo, useState, useRef, useEffect, forwardRef,useCallback } from "react";
import { Card, CardHeader, CardBody, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useForm, useFieldArray, FormProvider } from "react-hook-form";
import CustomInput from "components/form/CustomInput";
import CustomSelect from "components/form/CustomSelect";  
import { useHistory, Link, useLocation } from 'react-router-dom';
import { Config, ConfigEnum } from 'util/Config';
import { ProjectRole, EnumProjectRole, ApiKey } from "util/Constant";
import { Block } from "notiflix";
import Notiflix from "notiflix";
import CustomerDao from "data/CustomerDao";
import { handleSetPageOptions } from "util/Utility";
import ContactsTable from "components/tables/ContactsTable";
import { DataArrayTwoTone } from "@mui/icons-material";

const Contacts = (props) => {
    const { register, control, handleSubmit, setValue, errors, watch, reset } = useForm({
        status: 0,
        priority: 0,
    });

    const history = useHistory();

    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    const [customerList, setCustomerList] = useState([]);
    const [customers, setCustomers] = useState([]);
    const _tableRef = useRef();
    let dao = useMemo(() => new CustomerDao(), []);

    const [listQueryParams, setListQueryParams] = useState();
    const [contactsList, setContactsList] = useState([]);

    var contacts = [];
    const getCustomerList = useCallback(
        async (params) => {
    
          await dao.getCustomerList(token, params).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
              var data = response[ApiKey._API_DATA_KEY];
              var customers = [];
    
              data.map((item) => {
                var obj = {
                    label : item.fullName,
                    value : item.customerId,
                }
                customers.push(obj);
              });

              setCustomers(data);
              console.log(contacts)    
              setCustomerList(customers);
            } else {
              Notiflix.Report.failure(
                "Error",
                "Failed to load customer. Please try again later"
              );
            }
          });
    
        },
        [token, dao]
    );

    useEffect(() => {
      getCustomerList(listQueryParams);
    }, [getCustomerList]);

    const {
        handleNext,
      } = props;

      const onSubmit = (data) => {
        console.log(data);
        handleNext();
        
    }


    const addToContactsList = (data) => {
        //add the contacts into an array for dynamic display n to be stored afterwards

        var findCustomer = customers.find(x => x.customerId === watch("contactName"));

        console.log(findCustomer);
        setContactsList([...contactsList,
        {
            name: findCustomer.fullName,
            companyName: findCustomer.companyName,
            mobilePhone: findCustomer.mobileNumber,
            emailAddress: findCustomer.email,
            
            projectRole: EnumProjectRole[watch("projectRole")],
            remarks: watch("remarks"),

        }
        ]);
    };

    return (
        <form id="contactsForm" onSubmit={handleSubmit(onSubmit)}>
        <Row style={{backgroundColor:"#F0F9FF", margin: "10px"}}>
                    <Col md={10}>
                        <h1 className="page-subheader" style={{borderBottom: "2px solid #77C0F173"}}>
                            Contacts - "Project Title"
                        </h1>
                    </Col>
                    <Col md={2}>
                        <button type="reset" className='btn btn-default d-flex-center' style={{color:"#0099FF" , borderColor:"#0099FF", justifyContent:"center", margin:"auto", width:"100%"}}>Reset Fields &nbsp; <i className="fas fa-undo"></i></button>
                    </Col>
                    <Col md={6}>
                    <label className="custom-input-label">Contact Name</label>
                    
                    <CustomSelect
                      control={control}
                      name="contactName"
                      label="Contact Name"
                      placeholder="Choose the contact name"
                      options={customerList}
                      rules={{
                          required: true,
                      }}
                    /> 
                </Col>
                <Col md={6}>
                    <label className="custom-input-label">Project Role</label>
                    <CustomSelect
                      control={control}
                      name="projectRole"
                      label="Project Role"
                      placeholder="Choose the project role"
                      options={ProjectRole}
                      rules={{
                          required: true,
                      }}
                    /> 
                </Col>
                <Col md={12}>
                    <label className="custom-input-label">Remarks</label>
                    <CustomInput
                      {...register("remarks", {
                        required: "Remarks is required",
                      })}
                      name="remarks"
                      label="Remarks"        
                      showLabel
                    />
                </Col>
                <Col md={12}>
                    <br />
                    <button type="button" onClick={() => addToContactsList()} className='btn btn-default d-flex-center filter-btn' style={{color:"#0099FF" , borderColor:"#0099FF", justifyContent:"center", marginRight:"6px", width: "100%"}}>
                        Add Contact to Project
                    </button>
                    <br />
                </Col>
                <Col md={12}>                
                  <br />
                  <ContactsTable
                    tableTitle={(
                        <div className="d-flex flex-row align-items-center justify-between w-100">
                            <h1 className="page-subheader">Contacts List</h1>      
                        </div>
                    )}          
                    data={contactsList}
                    />
                </Col>


                <Col md={12}>
                    <br />
                    <button type="submit" className="btn btn-themed btn-block">Continue</button>                        
                    <br />
                </Col>
        </Row>
        </form>
    );


}

export default Contacts;