import React, { useMemo } from "react";
import ReactTable from 'components/react-table/ReactTable';

const ContactsTable = ({ data, tableClickEvent, setOpen, tableRef, ...rest }) => {
    const columns = useMemo(() => [
        {
            Header: "Name",
            accessor: "name",
          },
          {
            Header: "Company Name",
            accessor: "companyName",     
          },
          {
            Header: "Mobile Phone",
            accessor: "mobilePhone",     
          },  
          {
            Header: "Email Address",
            accessor: "emailAddress",     
          },  
          {
            Header: "Project Role",
            accessor: "projectRole",
          },  
          {
            Header: "Remarks",
            accessor: "remarks",     
          },
    ]);
    return <ReactTable columns={columns} data={data} ref={tableRef} {...rest} />;
}

export default ContactsTable;