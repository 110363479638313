import React, { useMemo, useState, useRef, useEffect, forwardRef } from "react";
import { Card, CardHeader, CardBody, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useForm, useFieldArray, FormProvider } from "react-hook-form";
import { PaymentStatuses, States, PrefixTypes, PostfixTypes, ProductTypes, Models, ProjectStatuses,
         ShopDrawingStatuses, WorkOrderStatuses, JKKPStatuses, EnumPrefix, EnumPostfix, EnumProductTypes,
         EnumProjectStatus, EnumShopDrawingStatus, EnumWorkOrderStatus, EnumJKKPStatus
        } from "util/Constant";
import CustomInput from "components/form/CustomInput";
import CustomSelect from "components/form/CustomSelect";  
import CustomDatePicker from "components/form/CustomDatePicker";
import NewProjectFileUpload from "components/form/NewProjectFileUpload";
import ProjectUnitTable from "components/tables/ProjectUnitTable";
import { useHistory, Link, useLocation } from 'react-router-dom';
import { Config, ConfigEnum } from 'util/Config';
import moment from 'moment';


const GeneralInfo =  (props) => {
    const [showUnit, setShowUnit] = useState(false);
    const [unitList, setUnitList] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [fileName, setFileName] = useState("");
    const [shopDrawing, setShopDrawing] = useState("");
    const [workOrder, setWorkOrder] = useState("");
    const [testingCommissioningForm, setTestingCommissioningForm] = useState("");
    const [completionForm, setCompletionForm] = useState("");
    const [handoverForm, setHandoverForm] = useState("");
    const { register, control, handleSubmit, setValue, errors, watch, reset } = useForm({
        status: 0,
        priority: 0,
    });

    const history = useHistory();

    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    {/*new function as handler */}
    const showUnitClicked = (data) => {
      setShowUnit((prev) => !prev);
    };

    const {
      handleNext,
    } = props;
  

    const EnumModels = {
      0: 'NEM-000001',
      1: 'NEM-000002',
      2: 'NEM-000003',
      3: 'NEM-000004',
    }

    const addToUnitList = (data) => {
    //add the units into an array for dynamic display n to be stored afterwards
    setUnitList([...unitList,
      {
        serialNumber: EnumPrefix[watch("prefix")] + " xxxxx" + EnumPostfix[watch("postfix")], 
        productType: EnumProductTypes[watch("productType")],
        model: EnumModels[watch("model")],
        projectStatus: EnumProjectStatus[watch("projectStatus")],
        shopDrawingStatus: EnumShopDrawingStatus[watch("shopDrawingStatus")],
        shopDrawing,
        workOrderStatus: EnumWorkOrderStatus[watch("workOrderStatus")],
        workOrder,
        jkkpStatus: EnumJKKPStatus[watch("jkkpStatus")],
        testingCommissioningForm,
        completionForm,
        handoverForm,
        testingCommissioningDate: moment(watch("testingCommissioningDate")).format("YYYY-MM-DD"),
        completionDate: moment(watch("completionDate")).format("YYYY-MM-DD"),
        handoverDate: moment(watch("handoverDate")).format("YYYY-MM-DD"),
        //left the dates then i think can submit form ler maybe
      }
        ]);
        showUnitClicked();
    };

    const onSubmit = (data) => {
        data["units"]= unitList;
        console.log(data);
        handleNext();
        
    }
    return (
    <form id="projectForm" onSubmit={handleSubmit(onSubmit)}>
    <Row style={{backgroundColor:"#F0F9FF", margin: "10px"}}>
                <Col md={10}>
                  <h1 className="page-subheader" style={{borderBottom: "2px solid #77C0F173"}}>
                      General Info
                  </h1>
                </Col>
                <Col md={2}>
                <button type="reset" className='btn btn-default d-flex-center' style={{color:"#0099FF" , borderColor:"#0099FF", justifyContent:"center", margin:"auto", width:"100%"}}>Reset Fields &nbsp; <i className="fas fa-undo"></i></button>
                </Col>
                <Col md={12}>
                    <label className="custom-input-label">Project Title</label>
                    <CustomInput
                      {...register("projectTitle", {
                        required: "Project Title is required",
                      })}
                      name="projectTitle"
                      label="Project Title"                
                    //  showErrorMsg={true}
                      showLabel
                    />
                </Col>        
                <Col md={12}>
                    <label className="custom-input-label">Site Project Title</label>
                    <CustomInput
                      {...register("siteProjectTitle", {
                        required: "Site Project Title is required",
                      })}
                      name="siteProjectTitle"
                      label="Site Project Title"                
                     // showErrorMsg={true}
                      showLabel
                    />
                </Col>         
                <Col md={6}>
                    <label className="custom-input-label">Building Design Type (optional)</label>
                    <CustomInput
                      name="buildingDesignType"
                      label="Building Design Type"                
                     // showErrorMsg={true}
                      showLabel
                    />
                </Col>   
                <Col md={6}>
                    <label className="custom-input-label">Project Phase (optional)</label>
                    <CustomInput
                      name="projectPhase"
                      label="Project Phase"                
                     // showErrorMsg={true}
                      showLabel
                    />
                </Col>      
                <Col md={6}>
                    <label className="custom-input-label">Quantity</label>
                    <CustomInput
                      {...register("quantity", {
                        required: "Quantity is required",
                      })}                    
                      name="quantity"
                      label="Quantity"                
                    //  showErrorMsg={true}
                      showLabel
                    />
                </Col>      
                <Col md={6}>
                    <label className="custom-input-label">Payment Status</label>
                    <CustomSelect
                      control={control}
                      name="paymentStatus"
                      label="Payment Status"
                      placeholder="Payment Status *"
                      options={PaymentStatuses}
                      rules={{
                          required: true,
                      }}
                    />                                        
                </Col>  
                
                <Col md={12}>
                <br />
                  <h1 className="page-subheader" style={{borderBottom: "2px solid #77C0F173"}}>
                      Site Address
                  </h1>
                </Col>
                <Col md={12}>
                    <label className="custom-input-label">Address Line 1</label>
                    <CustomInput
                      {...register("addressLine1", {
                        required: "Address Line 1 is required",
                      })}
                      name="addressLine1"
                      label="Address Line 1"                
                    //  showErrorMsg={true}
                      showLabel
                    />
                </Col> 
                <Col md={12}>
                    <label className="custom-input-label">Address Line 2</label>
                    <CustomInput
                      {...register("addressLine2", {
                        required: "Address Line 2 is required",
                      })}
                      name="addressLine2"
                      label="Address Line 2"                
                    //  showErrorMsg={true}
                      showLabel
                    />
                </Col>
                <Col md={4}>
                    <label className="custom-input-label">Postal Code</label>
                    <CustomInput
                      {...register("postalCode", {
                        required: "Postal Code is required",
                      })}
                      name="postalCode"
                      label="Postal Code"                
                  //    showErrorMsg={true}
                      showLabel
                    />
                </Col>
                <Col md={4}>
                    <label className="custom-input-label">City / Town</label>
                    {/* changed to text input */}
                    <CustomInput
                      {...register("cityTown", {
                        required: "City / Town is required",
                      })}
                      name="cityTown"
                      label="City / Town"                
                    //  showErrorMsg={true}
                      showLabel
                    />
                </Col>
                <Col md={4}>
                    <label className="custom-input-label">States</label>
                    <CustomSelect
                      control={control}
                      name="states"
                      label="States"
                      placeholder="Choose the state"
                      options={States}
                      rules={{
                          required: true,
                      }}
                    /> 
                </Col>
                <Col md={12}>
                    <label className="custom-input-label">GPS Location</label>
                </Col>
                <Col md={6}>
                    <label className="custom-input-label" style={{fontSize: "11px", fontWeight: "normal"}}>Longitude</label>
                    <CustomInput
                      {...register("longitude", {
                        required: "Longitud is required",
                      })}
                      name="longitude"
                      label="Longitude"                
                  //    showErrorMsg={true}
                      showLabel
                    />
                </Col><Col md={6}>
                    <label className="custom-input-label" style={{fontSize: "11px", fontWeight: "normal"}}>Latitude</label>
                    <CustomInput
                      {...register("latitude", {
                        required: "Latitude is required",
                      })}
                      name="latitude"
                      label="Latitude"                
                   //   showErrorMsg={true}
                      showLabel
                    />
                </Col>    

                <Col md={10}>                
                <br />
                  <h1 className="page-subheader" style={{borderBottom: "2px solid #77C0F173"}}>
                      Lists of Units
                  </h1>
                </Col>
                <Col md={2} style={{marginTop: "auto"}}>
                  <div className='btn btn-default d-flex-center filter-btn' style={{color:"#0099FF" , borderColor:"#0099FF", justifyContent:"center"}} onClick={showUnitClicked}>
                    <span>Add new unit +</span>
                  </div> </Col>
                  { /* action handler if __ is true then to what from here eh is put in the table inside here then rmb onClick ^ put the function that setShowUnit to true eh function*/
                    showUnit && (
                      <Col md={12}>
                        <Row style={{backgroundColor:"white", margin: "10px"}}>
                          <Col><br /></Col>
                            <Col md={12}>
                              <h4>
                                  Add New Unit
                              </h4>
                            </Col>
                            <Col md={12}>
                              <label className="custom-input-label">Serial Number</label>
                            </Col>
                            <Col md={6}>
                              <label className="custom-input-label">Prefix</label>
                                <CustomSelect
                                  control={control}
                                  name="prefix"
                                  label="Prefix"
                                  placeholder="Choose a prefix"
                                  options={PrefixTypes} 
                                  rules={{
                                      required: true,
                                  }}
                                /> 
                            </Col>
                            <Col md={6}>
                              <label className="custom-input-label">Postfix</label>
                              <CustomSelect
                                  control={control}
                                  name="postfix"
                                  label="Postfix"
                                  placeholder="Choose a postfix"
                                  options={PostfixTypes}
                                  rules={{
                                      required: true,
                                  }}
                                />
                            </Col>
                            <Col md={4}>
                              <label className="custom-input-label">Product Type</label>
                                <CustomSelect
                                  control={control}
                                  name="productType"
                                  label="Product Type"
                                  placeholder="Choose a product type"
                                  options={ProductTypes}
                                  rules={{
                                      required: true,
                                  }}
                                /> 
                            </Col>
                            <Col md={4}>
                              <label className="custom-input-label">Model</label>
                              <CustomSelect
                                  control={control}
                                  name="model"
                                  label="Model"
                                  placeholder="Choose a model"
                                  options={Models}
                                  rules={{
                                      required: true,
                                  }}
                                />
                            </Col>
                            <Col md={4}>
                              <label className="custom-input-label">Project Status</label>
                              <CustomSelect
                                  control={control}
                                  name="projectStatus"
                                  label="Project Status"
                                  placeholder="Choose the project status"
                                  options={ProjectStatuses}
                                  rules={{
                                      required: true,
                                  }}
                                />
                            </Col>
                            <Col md={6}>
                              <label className="custom-input-label">Shop Drawing Status</label>
                              <CustomSelect
                                  control={control}
                                  name="shopDrawingStatus"
                                  label="Shop Drawing Status"
                                  placeholder="Choose the shop drawing status"
                                  options={ShopDrawingStatuses}
                                  rules={{
                                      required: true,
                                  }}
                                />
                            </Col>
                            <Col md={6}>
                                <label className="custom-input-label">Shop Drawing</label>
                                <NewProjectFileUpload
                                  control={control}
                                  name="shopDrawing"
                                  setShopDrawing={setShopDrawing}
                                />
                            </Col>
                            <Col md={6}>
                              <label className="custom-input-label">Work Order Status</label>
                              <CustomSelect
                                  control={control}
                                  name="workOrderStatus"
                                  label="Work order Status"
                                  placeholder="Choose the work order status"
                                  options={WorkOrderStatuses}
                                  rules={{
                                      required: true,
                                  }}
                                />
                            </Col>
                            <Col md={6}>
                                <label className="custom-input-label">Work Order</label>
                                <NewProjectFileUpload
                                  control={control}
                                  name="workOder"
                                  setWorkOrder={setWorkOrder}
                                /> 
                            </Col>
                            <Col md={4}>
                              <label className="custom-input-label">JKKP Status</label>
                              <CustomSelect
                                  control={control}
                                  name="jkkpStatus"
                                  label="JKKP Status"
                                  placeholder="Choose the JKKP status"
                                  options={JKKPStatuses}
                                  rules={{
                                      required: true,
                                  }}
                                />
                            </Col>
                            <Col md={4}>
                              <label className="custom-input-label">Testing & Commissioning Date</label>
                              <CustomDatePicker
                                control={control}
                                {...register("testingCommissioningDate", false)}
                                name="testingCommissioningDate"
                                label="Testing & Commissioning Date"
                                onChange={null}
                              />
                            </Col>
                            <Col md={4}>
                              <label className="custom-input-label">Testing & Commissioning Form</label>
                              <NewProjectFileUpload
                                control={control}
                                name="testingCommissioningForm"
                                setTestingCommissioningForm={setTestingCommissioningForm}
                              />
                            </Col>
                            <Col md={6}>
                              <label className="custom-input-label">Completion Date</label>
                              <CustomDatePicker
                                control={control}
                                {...register("completionDate", false)}
                                name="completionDate"
                                label="Completion Date"
                                onChange={null}
                              />
                            </Col>
                            <Col md={6}>
                                <label className="custom-input-label">Completion Form</label>
                              <NewProjectFileUpload
                                control={control}
                                name="photo"
                                setCompletionForm={setCompletionForm}
                              />
                            </Col>
                            <Col md={6}>
                              <label className="custom-input-label">Handover Date</label>
                              <CustomDatePicker
                                control={control}
                                {...register("handoverDate", false)}
                                name="handoverDate"
                                label="Handover Date"
                                onChange={null}
                              />
                            </Col>
                            <Col md={6}>
                              <label className="custom-input-label">Handover Form</label>
                              <NewProjectFileUpload
                                control={control}
                                name="handoverForm"
                                setHandoverForm={setHandoverForm}
                              />
                            </Col>
                            <Col md={12}>
                              <br />
                              <button type="button" onClick={() => addToUnitList()} className='btn btn-default d-flex-center filter-btn' style={{color:"#0099FF" , borderColor:"#0099FF", justifyContent:"center", marginRight:"6px", width: "100%"}}>Add to Unit List</button>
                              <br />
                            </Col>
                        </Row>
                      </Col> 

                    ) 
                  }
                   
 
                <Col md={12}>                
                  <br />
                  <ProjectUnitTable
                    tableTitle={(
                        <div className="d-flex flex-row align-items-center justify-between w-100">
                            <h1 className="page-subheader">Units List</h1>      
                        </div>
                    )}        
                    //setQueryParams={setListQueryParams}
                    //tableClickEvent={tableClickEvent}            
                    data={unitList}                    
                    //tableRef={_tableRef}
                />
                <br /><br />
                </Col> 
                <Col md={12}>
                <button type="submit" className="btn btn-themed btn-block">Continue</button>
                <br />
                </Col> 
                </Row>
                </form>
    )
}

export default GeneralInfo;