import React, { useMemo, useState, useRef, useEffect, forwardRef } from "react";
import ReactTable from 'components/react-table/ReactTable';
import { Card, CardHeader, CardBody, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Notiflix from "notiflix";
import { useHistory, Link, useLocation } from 'react-router-dom';
import { useForm, useFieldArray, FormProvider } from "react-hook-form";
import { Block, Report } from 'notiflix';
import moment from 'moment';
import NavigationButton from 'components/buttons/NavigationButton';
import { Stepper , Step, StepLabel} from "@mui/material";
import { PaymentStatuses } from "util/Constant";
import { States } from "util/Constant";
import { PrefixTypes } from "util/Constant";
import { PostfixTypes } from "util/Constant";
import { ProductTypes } from "util/Constant";
import { Models } from "util/Constant";
import { ProjectStatuses } from "util/Constant";
import { ShopDrawingStatuses } from "util/Constant";
import { WorkOrderStatuses } from "util/Constant";
import { JKKPStatuses } from "util/Constant";
import { Config, ConfigEnum } from 'util/Config';
import PropTypes from 'prop-types';
import Check from '@mui/icons-material/Check';
import CustomInput from "components/form/CustomInput";
import QontoConnector from "components/stepper/QConnector";
import QontoStepIconRoot from "components/stepper/QStepIcon";
import CustomSelect from "components/form/CustomSelect";  
import CustomDatePicker from "components/form/CustomDatePicker";
import NewProjectFileUpload from "components/form/NewProjectFileUpload";
import ProjectUnitTable from "components/tables/ProjectUnitTable";
import GeneralInfo from "./GeneralInfo";
import Contacts from "./Contacts";
import Contracts from "./Contracts";

  function QontoStepIcon(props) {
    const { active, completed, className } = props;
    console.log(props);
    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }
  
  QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
  };
  

///<summary>
///Author: Christopher Chan
///</summary>
const CreateNewProject = (props) => {
    const state = useLocation()?.state;
    const [quotation, setQuotation] = useState(state != undefined ? state?.quotation : {});
    const [quotationList, setQuotationList] = useState([]);
    const [quotationOptions, setQuotationOptions] = useState([]);
    const [quotationItems, setQuotationItems] = useState([]);
    const [users, setUsers] = useState([]);
    const [customerDetails, setCustomerDetails] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const steps = useMemo(() => {
        let temp = [];
        temp.push("General Info");
        temp.push("Contacts");
        temp.push("Contracts");
        temp.push("Site Note");
       
        return temp;
      }, []);

    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const getStepContent = (step) => {
      switch (step) {
        case 0:
          return (
            <GeneralInfo {...{handleNext,}} />
          );
        case 1:
          return (
            <Contacts {...{handleNext,}} />
          );
        case 2:
          return (
            <Contracts {...{handleNext,}} />
          );
      }
    };

    return <>
            <input type="hidden" name="quotationId" />

            <h1 className="page-header">
                <NavigationButton />
                Create New Project
            </h1>
            <Row style={{backgroundColor:"#F0F9FF", margin: "0px"}}>
                <Col md={12} style={{marginTop:"30px"}}>
                    <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
                        {steps.map((label) => {
                            return (
                            <Step key={label}>
                                <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                            </Step>
                            );
                        })}
                    </Stepper>
                  
                </Col>    
                <hr />
                <Col md={12}>
                  {getStepContent(activeStep)}
                </Col>
                {/*<Col md={12}>
                <button type="submit" className="btn btn-themed btn-block">Continue</button>
                <br />
                </Col>*/}   
            </Row>
           
    </>;
}
export default CreateNewProject;