import React, { useMemo } from "react";
import ReactTable from 'components/react-table/ReactTable';

const ProjectUnitTable = ({ data, tableClickEvent, setOpen, tableRef, ...rest }) => {
    const columns = useMemo(() => [
          {
            Header: "Serial Number",
            accessor: "serialNumber",
          },
          {
            Header: "Product Type",
            accessor: "productType",     
          },
          {
            Header: "Model",
            accessor: "model",     
          },  
          {
            Header: "Project Status",
            accessor: "projectStatus",     
          },  
          {
            Header: "Shop Drawing Status",
            accessor: "shopDrawingStatus",
            //disableSortBy: true,
            //disableFilters: true,
            //style: { overflow: "visible", textAlign: "right" },
            //sticky: "right"
          },  
          {
            Header: "Shop Drawing",
            accessor: "shopDrawing",     
          },  
          {
            Header: "Work Order Status",
            accessor: "workOrderStatus",     
          },  
          {
            Header: "Work Order",
            accessor: "workOrder",   
          },  
          {
            Header: "JKKP Status",
            accessor: "jkkpStatus",     
          },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ], []);

    return <ReactTable columns={columns} data={data} ref={tableRef} {...rest} />;
}

export default ProjectUnitTable;