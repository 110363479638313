import React, { useState, useEffect } from "react";
import ReactSelect from "react-select";
import { Controller } from "react-hook-form";
import classnames from "classnames";
import { Colors } from "util/Constant";

/// <summary>
/// Author: Christopher Chan
/// </summary>
const CustomSelect = (props) => {
  const {
    error,
    options,
    isMulti,
    selectClasses,
    creatable,
    readOnly,
    label,
    showLabel,
    onChange: propsOnChange,
    disabled,
    icon,
    errors,
    placeholder = "Select...",
    ...rest
  } = props;
  const [defaultValue, setDefaultValue] = useState(null);

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const _selectStyles = {
    container: (provided) => ({
      ...provided,
      marginBottom: "5px",
      paddingRight: 0,
      height: isMulti && "auto !important",
      border: "1px solid #f2f2f2",
      borderColor: error ? "red" : "#f2f2f2",
      borderRadius: 3,
      boxShadow: "0px 5px 5px -2px rgba(122,122,122,0.75)",
      "-webkit-box-shadow": "0px 5px 5px -2px rgba(122,122,122,0.75)",
      "-moz-box-shadow": "0px 5px 5px -2px rgba(122,122,122,0.75)",
    }),
    control: (provided, state) => ({
      ...provided,
      border: "none",
      height: isMulti ? "auto" : "29.5px",
      minHeight: "29.5px",
      boxShadow: 0,
      borderWidth: readOnly || disabled ? 0 : 2,
      background:  "rgba(119, 192, 241, 0.3)"
    }),
    option: (provided, { isDisabled, isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "rgba(119, 192, 241, 0.3)"
        : isFocused
        ? "rgba(119, 192, 241, 0.3)"
        : null,
      ":active": {
        ...provided[":active"],
        backgroundColor:
          !isDisabled && (isSelected ? Colors.OFF_THEME : Colors.OFF_THEME),
      },
      color: isSelected
        ? Colors.BLACK
        : isFocused
        ? Colors.BLACK
        : Colors.BLACK,
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "29.5px",
      paddingLeft: 15,
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "29.5px",
      display: readOnly || disabled ? "none" : "flex",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    placeholder: (provided, state) => ({
      ...provided,
    }),
    singleValue: (provided) => ({
      ...provided,
      paddingLeft: 0,
      transform: "translateY(-50%)",
    }),
    input: (provided) => ({
      ...provided,
      transform: "translateY(-50%)",
      position: isMulti ? "relative" : "absolute",
      height: isMulti && "14px",
      top: "50%",
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  const [toggleClass, setToggleClass] = useState();

  /// <summary>
  /// Author: Christopher Chan
  /// </summary>
  useEffect(() => {
    console.log(_selectStyles);
    setToggleClass(
      props.defaultValue !== null || props.defaultValue !== undefined
    );
    setDefaultValue(props.defaultValue);
  }, [props.defaultValue]);

  return (
    <div style={{ position: "relative" }}>
      {icon && (
        <div
          style={{
            position: "absolute",
            left: "12px",
            top: "5px",
            zIndex: 999,
            color: error ? "red" : "black",
          }}
        >
          {icon}
        </div>
      )}
      <Controller
        {...rest}
        render={({ field: { onChange }, value, ...otherProps }) => {
          return [
            <ReactSelect
              className={classnames({
                [selectClasses]: selectClasses,
                "react-select-selected": toggleClass || value,
                "form-control": false,
              })}
              onFocus={() => {
                setToggleClass(true);
              }}
              onMenuClose={() => {
                setToggleClass(value != null);
              }}
              onChange={(e) => {
                setToggleClass(isMulti ? e !== null : e.value !== null);
                console.log(e);
                onChange(isMulti ? e : e.value);
                setDefaultValue(e.value);
                propsOnChange && propsOnChange(isMulti ? e : e.value);
              }}
              value={
                isMulti
                  ? value
                  : options.length > 0 &&
                    options?.filter(
                      (x) =>
                        x.value ===
                        (defaultValue !== null ? defaultValue : value)
                    )
              }
              styles={_selectStyles}
              options={options}
              isMulti={isMulti}
              menuPortalTarget={document.body}
              isDisabled={readOnly || disabled}
              placeholder={placeholder}
              {...otherProps}
            />,
            <div>
              {errors && <span style={{ color: "red" }}>{errors}</span>}
            </div>,
          ];
        }}
      />
    </div>
  );
};

CustomSelect.defaultProps = {
  defaultValue: null,
  menuPlacement: "auto",
};

export default CustomSelect;
