import React, { useMemo } from "react";
import ReactTooltip from 'react-tooltip';
import moment from "moment";
import { DateFormat, AppFeatureKeys, Permission } from 'util/Constant';
import ReactTable from 'components/react-table/ReactTable';

/// <summary>
/// Author: Samuel
/// </summary>
const CustomerTable = ({ data, tableClickEvent, setOpen, tableRef, ...rest }) => {
    const columns = useMemo(() => [
        {
            Header: "Name",
            Cell: ({ row }) => (
                <>
                  <div  onMouseOver={() => setOpen(true)} onMouseOut={() => setOpen(false)} style={{color: row.original.is_active ? 'black' : 'grey'}}>   
                     {row.original.honorific} {row.original.fullName} (<small>{row.original.shortName}</small>)
                     {/* {open && <img
                               src={ 
                                 row.original?.photo
                                   ? HostKey.getApiHost() + row.original?.photo
                                   : NoImagePlaceholder
                               }
                             /> } */}
                  </div>
                </>
            ),     
        },
        {
            Header: "Company Name",
            Cell: ({ row }) => (
              <>
                <div style={{color: row.original.is_active ? 'black' : 'grey'}}>   
                   {row.original.companyName}
                </div>
              </>
            ),     
          },
          {
            Header: "Phone",
            width: 150,
            Cell: ({ row }) => (
              <>
                <div style={{color: row.original.is_active ? 'black' : 'grey'}}>   
                   {row.original.mobileNumber}
                </div>
              </>
            ),     
          },  
          {
            Header: "Email",
            width: 200,
            Cell: ({ row }) => (
              <>
                <div style={{color: row.original.is_active ? 'black' : 'grey'}}>   
                   {row.original.email}
                </div>
              </>
            ),     
          },  
          {
            Header: " ",
            Cell: ({ row }) => (
              <>               
                <div
                  className="btn-table"
                  title="View Customer"
                  onClick={() => tableClickEvent(row.original)}
                >
                  <i className="fa fa-eye"></i>
                </div>
              </>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible", textAlign: "right" },
            sticky: "right"
          },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ], []);

    return <ReactTable columns={columns} data={data} ref={tableRef} {...rest} />;
}

export default CustomerTable;