import React, { useMemo } from "react";
import ReactTable from 'components/react-table/ReactTable';

const DocumentTable = ({ data, tableClickEvent, setOpen, tableRef, ...rest }) => {
    const columns = useMemo(() => [
        
          {
            Header: "Filename",
            accessor: "documents.name",     
          }, 
          {
            Header: "Filesize",
            accessor: "documents.fileSize",     
          },  
          {
            Header: "Contract Type",
            accessor: "contractType",
          },  
          {
            Header: "Remarks",
            accessor: "remarks",     
          },
    ]);
    return <ReactTable columns={columns} data={data} ref={tableRef} {...rest} />;
}

export default DocumentTable;