import React from 'react';
import { Redirect } from 'react-router';
import { WebUrl } from './../util/Constant';

import Login from 'pages/common/LoginPage';
import Dashboard from 'pages/dashboard/Dashboard';
import Contract from 'pages/contract/Contract';
import Project from 'pages/project/Project';
import ProjectList from 'pages/project/ProjectList';
import ProjectTasks from 'pages/project/ProjectTasks';
import CreateProject from 'pages/project/CreateProject';
import CustomerList from 'pages/customer/CustomerList';
import ElevatorModelList from 'pages/elevator-model/ElevatorModelList';
import CreateNewProject from 'pages/project/CreateNewProject';

const routes = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to={WebUrl._LOGIN} />,
        authRequired: false,
    },
    {
        path: WebUrl._DASHBOARD,
        title: 'Dashboard',
        component: () => <Dashboard />,
        authRequired: true,
    },
    {
        path: WebUrl._LOGIN,
        title: 'Login',
        component: () => <Login />,
        authRequired: false,
    },
    {
        path: WebUrl._CONTRACT,
        title: 'Contract',
        component: () => <Contract />,
        authRequired: true,
    },
    {
        path: WebUrl._PROJECT,
        title: 'Project',
        component: () => <Project />,
        authRequired: true,
        exact: true,
    },
    {
        path: WebUrl._PROJECT_LIST,
        title: 'Project',
        component: () => <ProjectList />,
        authRequired: true,
        exact: true,
    },
    {
        path: WebUrl._CUSTOMER,
        title: 'Customers',
        component: () => <CustomerList />,
        authRequired: true,
        exact: true,
    },
    {
        path: WebUrl._ELEVATOR_MODELS,
        title: 'Elevator Models',
        component: () => <ElevatorModelList />,
        authRequired: true,
        exact: true,
    },
    {
        path: WebUrl._PROJECT_TASKS,
        title: 'Project Tasks',
        component: () => <ProjectTasks />,
        authRequired: true,
        exact: true,
    },
    {
        path: WebUrl._PROJECT_CREATE,
        title: 'Add Project',
        component: () => <CreateProject />,
        authRequired: true,
        exact: true,
    },
    {
        path: WebUrl._PROJECT_NEW,
        title: 'New Project',
        component: () => <CreateNewProject />,
        authRequired: true,
        exact: true,
    },
];


export default routes;