import React, { useState, useEffect, useMemo } from 'react';
import { Popper, ClickAwayListener, Autocomplete, Checkbox, InputBase } from '@mui/material';
import { isEmpty, omitBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import { convertToSelectOptions } from 'util/Utility';


/// <summary>
/// Author: Samuel
/// </summary>
const ShowColumns = (props) => {
    const {
        columns,
        setHiddenColumns,
    } = props;

    const { t } = useTranslation();

    const [columnOpts, setColumnOpts] = useState([]);
    const [anchorEl, setAnchorEl] = useState();
    const [selectedColumnOpts, setSelectedColumnOpts] = useState([]);

    const isPopperOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);

    useEffect(() => {
        if (!isEmpty(columnOpts)) {
            let tempHiddenCols = omitBy(columnOpts, opt => selectedColumnOpts.some(sel => sel.value === opt.value))
            setHiddenColumns(Object.values(tempHiddenCols).map(col => col.value))
        }
    }, [columns, selectedColumnOpts, columnOpts, setHiddenColumns])

    /// <summary>
    /// Author: Samuel
    /// </summary>
    useEffect(() => {
        if (!isEmpty(columns)) {
            let tempColumns = columns.filter(col => col.id.toLowerCase() !== 'action');
            let tempColumnOpts = convertToSelectOptions(tempColumns, "Header", "id", ["toggleHidden", "isDefaultHidden", "isRequiredColumn"]);
            setColumnOpts(tempColumnOpts);
            setSelectedColumnOpts(tempColumnOpts.filter(opt => !opt.isDefaultHidden))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns.length]);


    /// <summary>
    /// Author: Samuel
    /// </summary>
    const handleOpenShowColumns = (e) => {
        setAnchorEl(e.currentTarget);
    }

    /// <summary>
    /// Author: Samuel
    /// </summary>
    const handleCloseShowColumns = () => {
        setAnchorEl(null);
    }

    /// <summary>
    /// Author: Samuel
    /// </summary>
    const showAll = () => {
        setSelectedColumnOpts(
            columnOpts.filter(opt => {
                if (!opt.isDefaultHidden && opt.isRequiredColumn) {
                    return true
                }
                else if (!opt.isRequiredColumn) {
                    return true
                }
                return false;
            })
        )
    }

    /// <summary>
    /// Author: Samuel
    /// </summary>
    const hideAll = () => {
        setSelectedColumnOpts(
            columnOpts.filter(opt => {
                if (!opt.isDefaultHidden && opt.isRequiredColumn) {
                    return true
                }
                return false;
            })
        )
    }

    return (
        <>
            <div className='btn btn-default d-flex-center filter-btn' onClick={handleOpenShowColumns}>
                <i className="fas fa-columns"></i>
                <span>Show Columns</span>
            </div>
            <Popper open={isPopperOpen} anchorEl={anchorEl} placement='bottom-end' className="filter-popper">
                <ClickAwayListener onClickAway={handleCloseShowColumns}>
                    <div>
                        <Autocomplete
                            open
                            multiple
                            onClose={(event, reason) => {
                                if (reason === 'escape') {
                                    handleCloseShowColumns();
                                }
                            }}
                            disableCloseOnSelect
                            value={selectedColumnOpts}
                            onChange={(event, newValue, reason) => {
                                if (
                                    event.type === 'keydown' &&
                                    event.key === 'Backspace' &&
                                    reason === 'removeOption'
                                ) {
                                    return;
                                }
                                setSelectedColumnOpts(newValue);
                            }}
                            renderTags={() => null}
                            renderOption={({onClick, ...restProps}, option, { selected }) => (
                                <li {...restProps} onClick={(e) => { onClick(e); option.toggleHidden(selected) }}>
                                    <Checkbox
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {`${t(option.label)}${option?.isRequiredColumn ? '*': ''}`}
                                </li>
                            )}
                            getOptionDisabled={(option) => columnOpts.some(col => option === col && col?.isRequiredColumn)}
                            disablePortal
                            ListboxProps={{ className: 'filter-autocomplete-listbox y-scrollbar-1' }}
                            options={columnOpts}
                            renderInput={(params) => (
                                <InputBase
                                    ref={params.InputProps.ref}
                                    inputProps={params.inputProps}
                                    autoFocus
                                    placeholder={t('SEARCH')}
                                    size='small'
                                    fullWidth
                                    className="filter-autocomplete-inputbase"
                                />
                            )}
                        />
                        <div className='d-flex justify-between p-3'>
                            <div className='btn btn-default text-theme border-0' onClick={hideAll}>{t('HIDE_ALL')}</div>
                            <div className='btn btn-default text-theme border-0' onClick={showAll}>{t('SHOW_ALL')}</div>
                        </div>
                    </div>
                </ClickAwayListener>
            </Popper>

        </>
    )
}

export default ShowColumns;