import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Col, Collapse, Row } from 'reactstrap';
import { Popper, ClickAwayListener, Autocomplete, Checkbox, InputBase } from '@mui/material';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { isEmpty, isFunction, sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import { cleanObject, convertToSelectOptions } from 'util/Utility';
import { InputTypes } from 'util/Constant';

import InputHoc from 'components/form/InputHoc';
import { submitForm } from 'components/form/FormBuilder';

/// <summary>
/// Author: Samuel
/// </summary>
const FilterBar = (props) => {
    const {
        isOpen,
        columns,
        handleSearch: propsHandleSearch,
        customFilterComponent,
        customFilterOpts,
        parentWidth
    } = props;

    const { t } = useTranslation();
    const { control, handleSubmit, reset, unregister, watch } = useForm({ shouldUnregister: false });

    const [columnOpts, setColumnOpts] = useState([]);
    const [anchorEl, setAnchorEl] = useState();
    const [selectedColumnOpts, setSelectedColumnOpts] = useState([]);
    const [pendingOpts, setPendingOpts] = useState([]);
    const _formRef = useRef();
    const [formValues, setFormValues] = useState({});
    const firstRender = useRef(false);

    const isPopperOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);

    /// <summary>
    /// Author: Samuel
    /// </summary>
    useEffect(() => {
        if (!isEmpty(customFilterOpts)) {
            let requiredOpts = customFilterOpts.filter(col => col?.filterOptions?.isRequired);
            setColumnOpts(customFilterOpts);
            setSelectedColumnOpts(isEmpty(requiredOpts) ? [customFilterOpts[0]] : requiredOpts);
        }
        else {
            if (!isEmpty(columns)) {
                let defaultDisableFilters = ['action', 'createdbyuser'];
                let tempColumns = columns.filter(col => !defaultDisableFilters.includes(col.id.toLowerCase()) && col.disableFilters !== true);
                let tempColumnOpts = convertToSelectOptions(tempColumns, "Header", "id", ["filterOptions"]);
                let requiredOpts = tempColumnOpts.filter(col => col?.filterOptions?.isRequired);
                setColumnOpts(tempColumnOpts);
                setSelectedColumnOpts(isEmpty(requiredOpts) ? [tempColumnOpts[0]] : requiredOpts);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns.length, customFilterOpts]);

    /// <summary>
    /// Author: Samuel
    /// </summary>
    useEffect(() => {
        let values = columnOpts.filter(col => !selectedColumnOpts.some(selectedCol => selectedCol.value === col.value));
        unregister(values.map(val => val.value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unregister, selectedColumnOpts])

    /// <summary>
    /// Author: Samuel
    /// </summary>
    useEffect(() => {
        if (isFunction(propsHandleSearch) && firstRender.current) {
            propsHandleSearch(formValues);
        }
        !firstRender.current && (firstRender.current = true);
    },[propsHandleSearch, formValues])

    /// <summary>
    /// Author: Samuel
    /// </summary>
    const handleSearch = (data) => {
        setFormValues(cleanObject(data));
    }

    /// <summary>
    /// Author: Samuel
    /// </summary>
    const handleClearFilter = () => {
        reset();
        handleSearch();
    }

    /// <summary>
    /// Author: Samuel
    /// </summary>
    const handleMore = (e) => {
        setPendingOpts(selectedColumnOpts);
        setAnchorEl(e.currentTarget);
    }

    /// <summary>
    /// Author: Samuel
    /// </summary>
    const handleCloseMore = () => {
        setAnchorEl(null);
        setSelectedColumnOpts(pendingOpts);
    }

    /// <summary>
    /// Author: Samuel
    /// </summary>
    const getInputHocProps = (colOpt) => {
        let filterOpt = colOpt?.filterOptions;
        if (!isEmpty(filterOpt)) {
            switch (filterOpt?.inputType) {
                case InputTypes.MUI_SELECT:
                    return { inputType: filterOpt.inputType, options: filterOpt.options ?? [], defaultValue: filterOpt?.defaultValue }
                case InputTypes.MUI_DATEPICKER:
                    return { inputType: filterOpt.inputType, isRange: filterOpt?.isRange, startText: filterOpt?.startText, endText: filterOpt?.endText, defaultValue: filterOpt?.defaultValue }
                default:
                    return;
            }
        }
        return;
    }

    return (
        <Collapse isOpen={isOpen}>
            <div className="table-search">
                <form className="w-100" onSubmit={handleSubmit(handleSearch)} autoComplete='off' ref={_formRef}>
                    <Row className="w-100 m-0">
                        <Col className="m-auto">
                            {
                                customFilterComponent ?
                                    selectedColumnOpts.map((colOpt) => React.createElement(customFilterComponent, { watch, control, colOpt, getInputHocProps }))
                                    : <Row>
                                        {
                                            selectedColumnOpts.map((colOpt) => (
                                                <Col key={colOpt.value} md={parentWidth >= 800 ? 2 : parentWidth >= 500 ? 3 : 4} >
                                                    <InputHoc
                                                        formGroupClass="m-0"
                                                        control={control}
                                                        name={colOpt.value}
                                                        label={t(colOpt.label)}
                                                        size="small"
                                                        onKeyPress={(event) => 
                                                            {
                                                                if(event.key == "Enter") {
                                                                    submitForm(_formRef)
                                                                }
                                                            }
                                                        }
                                                        variant="outlined"
                                                        {...getInputHocProps(colOpt)}
                                                    />
                                                </Col>
                                            ))
                                        }

                                        <Col className="d-flex m-auto">
                                            <div className='btn text-theme btn-circle filter-btn ' onClick={handleMore}>
                                                <i className="fas fa-cog"></i>
                                                {parentWidth >= 800 && <span>{t('MORE')}</span>}
                                            </div>
                                            <div role='button' aria-label='Search' className='btn btn-default btn-circle filter-btn ml-2' onClick={() => submitForm(_formRef)}>
                                                <i className="fas fa-search"></i>
                                                {parentWidth >= 800 && <span>{t('SEARCH')}</span>}
                                            </div>
                                            <div className='btn btn-silver btn-circle filter-btn ml-2' onClick={() => handleClearFilter()}>
                                                <i className="fas fa-undo"></i>
                                                {parentWidth >= 800 && <span>{t('RESET')}</span>}
                                            </div>
                                        </Col>
                                    </Row>
                            }
                        </Col>
                    </Row>
                </form>
            </div>
            <Popper open={isPopperOpen} anchorEl={anchorEl} placement='bottom-end' className="filter-popper">
                <ClickAwayListener onClickAway={handleCloseMore}>
                    <div>
                        <Autocomplete
                            open
                            multiple
                            onClose={(event, reason) => {
                                if (reason === 'escape') {
                                    handleCloseMore();
                                }
                            }}
                            disableCloseOnSelect
                            value={pendingOpts}
                            onChange={(event, newValue, reason) => {
                                if (
                                    event.type === 'keydown' &&
                                    event.key === 'Backspace' &&
                                    reason === 'removeOption'
                                ) {
                                    return;
                                }
                                setPendingOpts(newValue);
                            }}
                            renderTags={() => null}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {t(option.label)}
                                </li>
                            )}
                            getOptionDisabled={(option) => columnOpts.some(col => option === col && col?.filterOptions?.isRequired)}
                            disablePortal
                            ListboxProps={{className: 'filter-autocomplete-listbox y-scrollbar-1'}}
                            options={sortBy(columnOpts, (item) => selectedColumnOpts.indexOf(item) !== -1 ? selectedColumnOpts.indexOf(item) : selectedColumnOpts.length + columnOpts.indexOf(item))}
                            renderInput={(params) => (
                                <InputBase
                                    ref={params.InputProps.ref}
                                    inputProps={params.inputProps}
                                    autoFocus
                                    placeholder={t('SEARCH')}
                                    size='small'
                                    fullWidth
                                    className="filter-autocomplete-inputbase"
                                />
                            )}
                        />
                    </div>
                </ClickAwayListener>
            </Popper>
        </Collapse>
    )
}

FilterBar.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    columns: PropTypes.array.isRequired,
    handleSearch: PropTypes.func,
}

FilterBar.defaultProps = {
    isOpen: false,
    columns: [],
    handleSearch: () => { },
}

export default FilterBar;