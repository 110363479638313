import { WebUrl } from "util/Constant";

const Menu = [
    { path: WebUrl._DASHBOARD, icon: 'las la-tachometer-alt', title: 'Dashboard' },
    // { path: WebUrl._CONTRACT, icon: 'las la-file-contract', title: 'Contract' },
    { path: WebUrl._CUSTOMER, icon: 'las la-user', title: 'Customers' },
    { path: WebUrl._ELEVATOR_MODELS, icon: 'las la-hotel', title: 'Elevator Models' },
    { path: WebUrl._PROJECT_LIST, icon: 'las la-project-diagram', title: 'Project' }
];

export const AdminMenu = [
];

export default Menu;
