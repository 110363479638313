const _DEV_HOST = "http://localhost:8000";
const _SIT_HOST = "http://nicheapi.gzit.com.my";
const _PROD_HOST = "https://api.niche123.net";

/// <summary>
/// Author : Robin
/// Host for different Environment
/// </summary>
export class HostKey {
    static getApiHost = () => {
        switch (process.env.REACT_APP_ENV) {
            case "dev":
                return _DEV_HOST;
    
            case "sit":
                return _SIT_HOST;
    
            case "prod":
                return _PROD_HOST;
    
            default:
                return (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? _DEV_HOST : _SIT_HOST;
        }
    }
}

export const _IMAGE_URL = `${HostKey.getApiHost()}/storage`;

/// <summary>
/// Author : -
/// API related key or field when making ajax or api call
/// </summary>
export class ApiKey {
    static _API_SUCCESS_KEY = "success";
    static _API_MESSAGE_KEY = "message";
    static _API_DATA_KEY = "data";

    static _API_CONTENT_TYPE = 'Content-Type';
    static _API_ACCEPT = "Accept";
    static _API_APPLICATION_JSON = "application/json";
    static _API_XML_HTTP_REQUEST_KEY = "XMLHttpRequest";
    static _API_FORM_URLENCODED = "application/x-www-form-urlencoded";
    static _API_FORM_DATA = "multipart/form-data";
    static _API_POST = "POST";
    static _API_GET = "GET";
    static _API_DELETE = "DELETE";
}

/// <summary>
/// Author : -
/// Url to communicate to controller
/// </summary>
export class ApiUrl {
    //neps 
    static _API_LOGIN = "/auth/login";
    static _API_VALIDATE_LOGIN = "/auth/user";
    static _API_LOGOUT = '/auth/logout';
    static _API_CONTRACT_LIST = "/contract";
    static _API_CREATE_CONTRACT = "/contract";
    static _API_EMPTY_CONTRACT_QUOTATION_LIST = "/quotation/emptyContract";
    static _API_UPLOAD_SIGNED_DOCUMENT = '/contract/update/status';
    static _API_UPDATE_CONTRACT = '/projectContract/update';
    static _API_GET_CONTRACT_SITES = "/projectContract/sites";
    static _API_GET_SITE_CONTRACTS = "/projectContract/site/contracts";
    static _API_QUOTATION_LIST = '/quotation/list';
    static _API_QUOTATION_ITEMS = '/quotation/items';
    static _API_GET_PROJECT = "/project/list";
    static _API_CREATE_PROJECT = '/project/create';
    static _API_UPDATE_PROJECT = '/project/update';
    static _API_GET_PROJECT_TASKS_BY_ID = '/project/task/list';
    static _API_REMOVE_PROJECT_TASK = '/project/task/delete';
    static _API_UPDATE_TASK = '/project/task/update';
    static _API_GET_USER = '/user/list';
    static _API_SERVICE_AGENT_LIST = '/service-agent/list';
    static _API_PROJECT_COMPLETE = '/project/complete';
    static _API_CUSTOMER_LIST = "/customer/list";
    static _API_ELEVATOR_MODELS = "/elevator-models";
}

/// <summary>
/// Author : -
/// Url to navigate to pages
/// </summary>
export class WebUrl {
    //neps
    static _LOGIN = "/login";
    static _DASHBOARD = "/dashboard";
    static _CONTRACT = "/contract";
    static _PROJECT = "/project";
    static _PROJECT_LIST = "/project-list";
    static _PROJECT_TASKS = "/project/tasks";
    static _PROJECT_CREATE = "/project/create";
    static _PROJECT_NEW = "/project/new";
    static _CUSTOMER = "/customer-list";
    static _ELEVATOR_MODELS = "/elevator-model-list";
}

/// <summary>
/// Author : -
/// </summary>
export class SessionKey {
    static _LANGUAGE = "language";
}

/// <summary>
/// Author : -
/// </summary>
export class Language {
    static _ENGLISH_GB = "en-GB";
    static _CHINESE_HK = "zh-HK";
    static _CHINESE_CN = "zh-CN";
}

/// <summary>
/// Author: Christopher Chan
/// </summary>
export class Status {
    static _DELETED = -2;
    static _REJECTED = -1;
    static _INACTIVE = 0;
    static _ACTIVE = 1;
    static _PENDING = 2;
    static _EXPIRED = 3;
}

/// <summary>
/// Author: Christopher Chan
/// </summary>
export const BtnTypes = {
    ADD: 'add',
    DELETE: 'delete',
    VIEW: 'view',
    EDIT: 'edit',
    MISC: 'misc',
}

/// <summary>
/// Author: Christopher Chan
/// </summary>
export const InputTypes = {
    INPUT: 'input',
    SELECT: 'select',
    RADIO: 'radio',
    PHONE: 'phone',
    TEXTAREA: 'textarea',
    ATTACHMENT: 'attachment',
    DATEPICKER: 'datepicker',
    CHECKBOX: 'checkbox',
    FILEUPLOAD: 'fileupload',
    IMAGEUPLOADER: 'imageuploader'
}

export const  PaymentStatuses = [
    { label: 'Pending', value: 0 },
    { label: 'Healthy', value: 1 },
    { label: 'Underpaid', value: 2 },
    { label: 'Overdue', value: 3 },
    { label: 'Cleared', value: 4 }
];

/// <summary>
/// Author: Lewis
/// </summary>
export const SelectField = {
    STATUS_OPTIONS: [
        { label: 'Pending', value: 0 },
        { label: 'In Progress', value: 1 },
        { label: 'Completed', value: 2 }
    ],
    TYPE_OF_SERVICE_OPTIONS: [
        { label: 'Preventive Service', value: 'Preventive Service' },
        { label: 'Inspection Service', value: 'Inspection Service' },
    ],
    TYPE_OF_LIFT_OPTIONS: [
        { label: 'Passenger', value: 'Passenger' },
        { label: 'Goods', value: 'Goods' },
        { label: 'Hydraulic', value: 'Hydraulic' },
        { label: 'MRL', value: 'MRL' },
    ]
}

export const SidebarType = {
    _ACCOUTANT: 1,
    _ADMIN: 2
}

/// <summary>
/// Author: Christopher Chan
/// </summary>
export const Colors = {
    THEME: '#5E96AE',
    OFF_THEME: '#eff6f3',
    WHITE: '#FFF',
    RED: '#f64e60',
    GRAY: '#dfe1e4',
    BLACK : '#000'
}

export const  States = [
    { label: 'Penang', value: 0 },
    { label: 'Kuala Lumpur', value: 1 },
    { label: 'Putrajaya', value: 2 },
    { label: 'Selangor', value: 3 },
    { label: 'Kedah', value: 4 },
    { label: 'Perlis', value: 5 },
    { label: 'Perak', value: 6 },
    { label: 'Kelantan', value: 7 },
    { label: 'Pahang', value: 8 },
    { label: 'Terengganu', value: 9 },
    { label: 'Negeri Sembilan', value: 10 },
    { label: 'Melaka', value: 11 },
    { label: 'Johor', value: 12 },
    { label: 'Sabah', value: 13 },
    { label: 'Sarawak', value: 14 },
    { label: 'Labuan', value: 15 }
];

export const  PrefixTypes = [
    { label: 'SNE', value: 0 },
    { label: 'NE', value: 1 },
    { label: 'EX', value: 2 }
];
export const  PostfixTypes = [
    { label: 'A-18', value: 0 },
    { label: 'H-18', value: 1 },
    { label: 'D', value: 2 },
    { label: 'G', value: 3 },
    { label: 'VLP', value: 4 },
    { label: 'C', value: 5 },
    { label: 'DL', value: 6 },
    { label: 'S', value: 7 },
    { label: 'H', value: 8 },
    { label: 'SL', value: 9 },
    { label: 'IPL', value: 10 },
    { label: 'CL', value: 11 },
    { label: 'RL', value: 12 },
    { label: 'HG', value: 13 },
    { label: 'VPL', value: 14 },
    { label: 'PL', value: 15 },
    { label: 'HR', value: 16 },
    { label: 'TR', value: 17 },
    { label: 'LU', value: 18 },
    { label: 'TT', value: 19 },
    { label: 'HP', value: 20 },
    { label: 'GL', value: 21 },
    { label: 'MRL', value: 22 },
    { label: 'TRL', value: 23 },
    { label: 'HGL', value: 24 },
    { label: 'TVL', value: 25 },
    { label: 'MRLH', value: 26 },
    { label: 'MRLC', value: 27 },
    { label: 'MRLB', value: 28 },
    { label: 'B-18', value: 29 },
    { label: 'H-16', value: 30 }
];

export const  ProductTypes = [
    { label: 'Home Lift', value: 0 },
    { label: 'OKU Lift', value: 1 },
    { label: 'Dumbwaiter', value: 2 },
    { label: 'Goods Hoist', value: 3 },
    { label: 'Scissor Lift', value: 4 },
    { label: 'Mini Passenger Lift', value: 5 },
    { label: 'Nano Lift', value: 6 },
    { label: 'Others', value: 7 }
];

export const  Models = [
    { label: 'NEM-000001', value: 0 },
    { label: 'NEM-000002', value: 1 },
    { label: 'NEM-000003', value: 2 },
    { label: 'NEM-000004', value: 3 }
];

export const  ProjectStatuses = [
    { label: 'Pending', value: 0 },
    { label: 'Delivered', value: 1 }
];

export const  ShopDrawingStatuses = [
    { label: 'Pending', value: 0 },
    { label: 'Completed', value: 1 }
];

export const  WorkOrderStatuses = [
    { label: 'Pending', value: 0 },
    { label: 'Approved', value: 1 }
];

export const  JKKPStatuses = [
    { label: 'Pending', value: 0 },
    { label: 'DA-A', value: 1 }
];

export const EnumPrefix = {
    0: "SNE", 1: "NE", 2: "EX"
  }

export const EnumPostfix = {
      0: ' A-18',   1: ' H-18',   2: ' D',      3: ' G',      4: ' VLP',  5: ' C',
      6: ' DL',     7: ' S',      8: ' H',      9: ' SL',     10: ' IPL',
      11: ' CL',    12: ' RL',    13: ' HG',    14: ' VPL',   15: ' PL',
      16: ' HR',    17: ' TR',    18: ' LU',    19: ' TT',    20: ' HP',
      26: ' MRLH',  27: ' MRLC',  28: ' MRLB',  29: ' B-18',  30: ' H-16',
  }

export const EnumProductTypes = {
    0: 'Home Lift', 
    1: 'OKU Lift', 
    2: 'Dumbwaiter', 
    3: 'Goods Hoist', 
    4: 'Scissor Lift', 
    5: 'Mini Passenger Lift',
    6: 'Nano Lift', 
    7: 'Others', 
  }

  export const EnumProjectStatus = {
    0: "Pending",
    1: "Delivered",
  }

  export const EnumShopDrawingStatus = {
    0: "Pending",
    1: "Completed",
  }

  export const EnumWorkOrderStatus = {
    0: "Pending",
    1: "Approved",
  }

  export const EnumJKKPStatus = {
    0: "Pending",
    1: "DA-A",
  }

  export const ProjectRole = [
    { label: "Owner (Developer)", value: 0},
    { label: "Owner (Individual)", value: 1},
    { label: "Architect", value: 2},
    { label: "M & E", value: 3},
    { label: "C & S", value: 4},
    { label: "QS", value: 5},
    { label: "Main-con", value: 6},
    { label: "I.D. Sub-con", value: 7},
    { label: "Infrastructure Sub-con", value: 8},
    { label: "Builder Sub-con (lay bricks, plastering works)", value: 9},
    { label: "Electrical Sub-con", value: 10},
    { label: "CCTV Sub-con", value: 11},
    { label: "Smart Home Sub-con", value: 12},
    { label: "Plumbing Sub-con", value: 13},
    { label: "Ceiling Sub-con", value: 14},
    { label: "Flooring Sub-con", value: 15},
    { label: "Painter Sub-con", value: 16},
    { label: "Landscape Sub-con", value: 17}
];

  export const EnumProjectRole = {
    0: "Owner (Developer)",
    1: "Owner (Individual)",
    2: "Architect",
    3: "M & E",
    4: "C & S",
    5: "QS",
    6: "Main-con",
    7: "I.D. Sub-con",
    8: "Infrastructure Sub-con",
    9: "Builder Sub-con (lay bricks, plastering works)",
    10: "Electrical Sub-con",
    11: "CCTV Sub-con",
    12: "Smart Home Sub-con",
    13: "Plumbing Sub-con",
    14: "Ceiling Sub-con",
    15: "Flooring Sub-con",
    16: "Painter Sub-con",
    17: "Landscape Sub-con",
  }

  export const NumWeeks = [
    {label:1, value:1},
    {label:2, value:2},
    {label:3, value:3},
    {label:4, value:4},
  ];

  export const ContractType = [
    {label:"PO", value:0},
    {label:"LOI", value:1},
    {label:"LOA", value:2},
    {label:"SA", value:3},
    {label:"VO", value:4},
    {label:"BQ", value:5},
    {label:"DWG", value:6},
  ];

  export const EnumContractType = {
    0: "PO",
    1: "LOI",
    2: "LOA",
    3: "SA",
    4: "VO",
    5: "BQ",
    6: "DWG",
  }
