import React, { useMemo, useState, useRef, useEffect, forwardRef } from "react";
import ReactTable from 'components/react-table/ReactTable';
import { Card, CardHeader, CardBody, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Notiflix from "notiflix";
import { useHistory, Link } from 'react-router-dom';
import { InputTypes, SelectField, WebUrl, ApiKey } from "util/Constant";
import InputHoc from "components/form/InputHoc.js";
import { useForm, useFieldArray } from "react-hook-form";
import ReactTooltip from 'react-tooltip';

import { Config, ConfigEnum } from 'util/Config';
import ProjectTable from "components/tables/ProjectTable";

///<summary>
///Author: Sim
///</summary>
const ProjectList = props => {
    const [modal, setModal] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [project, setProject] = useState(null);

    const [sneId, setSneId] = useState(null);
    const history = useHistory();
    const formRef = useRef();
    const _tableRef = useRef();

    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    return (
        <>
            <div style={{ maxHeight: '65vh' }}>
                <ProjectTable
                    tableTitle={(
                        <div className="d-flex flex-row align-items-center justify-between w-100">
                            <h2 className="m-b-0">Project List</h2>                            
                                <div className="btn btn-themed ml-2" onClick={() => history.push(WebUrl._PROJECT_NEW)}>
                                <span>New Project  </span>
                                <i className="fa fa-plus"></i>                            
                                </div>                            
                        </div>
                    )}                    
                    data={[]}                    
                    tableRef={_tableRef}
                />
            </div>
        </>
    )
}

export default ProjectList;

